<template>
    <el-card class="subStatistical">
        <div class="searchBox">
            <el-form ref="form" :inline="true" :model="searchParams" label-width="70px">
                <el-form-item label="起止月份">
                    <el-date-picker
                        style="margin-right: 10px"
                        v-model="month"
                        type="monthrange"
                        range-separator="至"
                        start-placeholder="开始月份"
                        end-placeholder="结束月份"
                        :default-value="month"
                        value-format="yyyy-MM"
                        @change="onChangeDate"
                        :picker-options="monthpickoption"
                        :clearable="false"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="行政区划">
                    <el-select class="w200px" clearable multiple v-model="searchParams.org" placeholder="请选择所属区划">
                        <el-option v-for="item in orgIdoptions" :key="item.organizationId" :label="item.organizationName" :value="item.organizationId"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="补贴等级">
                    <el-select class="w200px" clearable multiple v-model="searchParams.subsidyType" @change="chooeseLevel" placeholder="请选择补贴等级">
                        <el-option v-for="item in subsidyLevelConfig" :key="item.id" :label="item.levelName" :value="item.id"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" class="search" @click="doSearch">搜索</el-button>
                    <el-button type="primary" plain @click="doReset">重置</el-button>
                </el-form-item>
                <el-button type="primary" class="add" @click="doExport">导出</el-button>
            </el-form>
        </div>
        <div style="margin-bottom: 15px; font-size: 14px">温馨提示：不包含期间已停发的人次</div>
        <div class="content">
            <el-table v-loading="loading" :data="tableData" border style="width: 100%" :header-cell-style="headerCellStyle">
                <el-table-column type="index" label="序号" width="80px" align="center" />
                <el-table-column prop="streetName" label="行政区划" width="180px" align="center" />
                <el-table-column v-for="item in chooeseLevelOption" :key="item.id" :label="item.levelName" align="center">
                    <el-table-column prop="userPhone" label="补贴人数（人次）" align="center">
                        <template slot-scope="scope">
                            <span>{{ getValue(scope.row.levelList, item.id, 'count') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="age" label="补贴金额（元）" align="center">
                        <template slot-scope="scope">
                            <span>{{ getValue(scope.row.levelList, item.id, 'money') }}</span>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="合计" align="center">
                    <el-table-column prop="totalCount" label="补贴人数（人次）" align="center" />
                    <el-table-column prop="totalMoney" label="补贴金额（元）" align="center" />
                </el-table-column>
            </el-table>
            <el-pagination
                style="margin: 20px 0"
                background
                layout="total, sizes, prev, pager, next"
                :total="total"
                :page-size.sync="queryParams.pageSize"
                :current-page.sync="queryParams.pageNum"
                :page-sizes="[10, 20, 30, 40]"
                @size-change="doSearch"
                @current-change="getList"
            />
        </div>
    </el-card>
</template>

<script>
import { administrativeDivision } from '@/api/institutionalManage'
import { getPensionSubsidyLevelNameList } from '@/api/highCollarSubsidy'
import { SEXLIST } from '@/data/dataDictionary'
import { statistics, statisticsExport } from '@/api/highCollarSubsidy'
import { downloadStream } from '@/utils/index'

export default {
    // 补贴统计
    name: 'subStatistical',
    data() {
        return {
            SEXLIST, // 性别
            minDate: null,
            maxDate: null,
            chooeseLevelOption: [],
            subsidyLevelConfig: [], // 补贴等级
            orgIdoptions: [], // 所属区划
            month: '',
            searchParams: {
                orgId: '',
                subsidyType: ''
            },
            queryParams: {
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            loading: false,
            tableData: [],
            monthpickoption: {
                disabledDate: time => {
                    if (this.minDate !== null && this.maxDate === null) {
                        let minMonth = this.minDate.getMonth() - 1,
                            lastYear = new Date(this.minDate).setMonth(minMonth - 11),
                            nextYear = new Date(this.minDate).setMonth(minMonth + 11)
                        let minTime = this.minDate.getTime()
                        let nextTime = new Date().setMonth(new Date().getMonth() + 11)
                        let lastTime = new Date().setMonth(new Date().getMonth() - 11)
                        if (minTime <= nextTime || minTime >= lastTime) {
                            //开始日期少于当前月+12个月 并且大于当前月-12个月，则表示只限制前面的范围
                            return time.valueOf() > new Date().getTime() || time.valueOf() > nextYear.valueOf() || time.valueOf() < lastYear.valueOf()
                        } else {
                            // 只能选 minDate 前后一年的范围
                            return time.valueOf() > new Date().setMonth(new Date().getMonth() - 1) || time.valueOf() > nextYear.valueOf() || time.valueOf() < lastYear.valueOf()
                        }
                    } else {
                        let startMonth = this.month[0]
                        let month = new Date(startMonth).getMonth()
                        let lastMonth = new Date(startMonth).setMonth(month - 11)
                        //如果有默认值，只禁用开始日期，因为已经限定了禁用未来范围，且默认值已经为12个月范围
                        return this.monthPick(time) || time < lastMonth.valueOf()
                    }
                },
                onPick: ({ minDate, maxDate }) => {
                    this.minDate = minDate
                    this.maxDate = maxDate
                }
            }
        }
    },
    mounted() {
        this.subsidyLevelData()
        this.getArea()
        this.initMonth()
        this.getList()
    },
    methods: {
        // 表格样式
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex % 2 === 0) {
                return 'statistics-warning-row'
            } else {
                return ''
            }
        },
        // 补贴等级
        async subsidyLevelData() {
            const res = await getPensionSubsidyLevelNameList()
            this.subsidyLevelConfig = res.data
            this.chooeseLevelOption = res.data
        },
        // 获取行政区划
        async getArea() {
            const res = await administrativeDivision()
            const id = res.data[0].organizationId
            const result = await administrativeDivision({ organizationId: id })
            console.log(result, 'result')
            this.orgIdoptions = result.data
        },
        // 选择月份
        onChangeDate(val) {
            console.log(val, 'onChangeDate')
            this.minDate = val ? `${val[0]}-01` : null
            this.maxDate = val ? `${val[1]}-28` : null
        },
        initMonth() {
            // 获取当前的月份信息
            const date = new Date() // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
            const year = date.getFullYear() // 获取当前年份，值是这样的： 2021
            let month = date.getMonth() + 1 // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
            const minMonth = `${year}-${month - 2 < 10 ? '0' + (month - 2) : month - 2}`
            const maxMonth = `${year}-${month - 1 < 10 ? '0' + (month - 1) : month - 1}`
            this.month = [minMonth, maxMonth]
            this.minDate = `${minMonth}-01`
            this.maxDate = `${maxMonth}-28`
        },
        monthPick(time) {
            // 获取当前的月份信息
            const date = new Date() // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
            const year = date.getFullYear() // 获取当前年份，值是这样的： 2021
            let month = date.getMonth() + 1 // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
            if (month >= 1 && month <= 9) {
                // 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
                month = '0' + (month - 1)
            }
            const nowDate = year.toString() + month.toString() // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107

            // 获取时间选择器的月份信息
            const timeyear = time.getFullYear() // 获取时间选择器的年份（有很多）
            let timemonth = time.getMonth() + 1 // 与上面同理
            if (timemonth >= 1 && timemonth <= 9) {
                timemonth = '0' + timemonth
            }
            const elTimeData = timeyear.toString() + timemonth.toString()

            // 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
            // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
            // 大于等于当前月份都不可选
            return elTimeData > nowDate // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
        },
        // 搜索
        doSearch() {
            this.queryParams.pageNum = 1
            this.getList()
        },
        // 重置
        doReset() {
            this.initMonth()
            this.searchParams = {
                orgId: '',
                subsidyType: ''
            }
            this.doSearch()
        },
        getList() {
            this.loading = true
            const data = {
                startTime: this.minDate,
                endTime: this.maxDate,
                tenantId: this.searchParams.org ? this.searchParams.org.join(',') : '',
                levelId: this.searchParams.subsidyType ? this.searchParams.subsidyType.join(',') : ''
            }
            statistics({ ...this.queryParams, ...data })
                .then(res => {
                    console.log(res)
                    this.tableData = res.data.rows
                    this.total = res.data.total
                    this.loading = false
                })
                .catch(() => {
                    this.tableData = []
                    this.total = 0
                    this.loading = false
                })
        },
        // 导出
        async doExport() {
            const data = {
                startTime: this.minDate,
                endTime: this.maxDate,
                tenantId: this.searchParams.org ? this.searchParams.org.join(',') : '',
                levelId: this.searchParams.subsidyType ? this.searchParams.subsidyType.join(',') : ''
            }
            const res = await statisticsExport({ ...data, pageNum: 1, pageSize: 999 })
            downloadStream(res, '补贴统计.xlsx')
        },
        // 获取表格数据
        getValue(array, id, key) {
            if (array) {
                const result = array.find(item => item.levelId === id)
                if (result) {
                    return result[key] ? result[key] : 0
                } else {
                    return 0
                }
            } else {
                return 0
            }
        },
        // 获取选择等级
        chooeseLevel(ids) {
            console.log(ids)
            if (ids.length > 0) {
                const newArr = ids.map(item1 => {
                    return this.subsidyLevelConfig.find(item2 => item2.id === item1)
                })
                this.chooeseLevelOption = newArr
            } else {
                this.chooeseLevelOption = this.subsidyLevelConfig
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.subStatistical {
    ::v-deep .el-table th.is-leaf,
    .el-table--border th.is-leaf,
    .el-table--group th.is-leaf {
        border-right: 1px solid #dfe6ec;
    }
    .searchBox {
        .w200px {
        }
        .search {
            margin-left: 20px;
        }
        .add {
            float: right;
        }
    }
}
</style>
